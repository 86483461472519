<template>
    <b-overlay :show="isLoading" rounded="sm">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-custom card-stretch gutter-b">
                    <div class="card-header border-0 pt-5">
                        <h3 class="card-title align-items-start flex-column">
            <span class="card-label font-weight-bolder text-dark"
            >{{ userAction }}</span
            >
                        </h3>
                        <div class="card-toolbar">
                            <a
                                    @click="goList"
                                    class="btn btn-secondary font-weight-bolder font-size-sm mr-3"
                            >
                                Volver
                            </a>
                        </div>
                    </div>
                    <div class="card-body pb-10 pb-2">
                        <!-- <p>
                            Be sure to use an appropriate <code>type</code> on all inputs
                            (e.g., email for email address or number for numerical
                            information) to take advantage of newer input controls like
                            <code>email</code> verification, <code>number</code> selection,
                            and more.
                          </p> -->
                        <div>
                            <b-form @submit="onSubmitCourse" @reset="onReset" v-if="show">
                                <div class="row">
                                    <div class="col-md-6">
                                        <b-form-group id="input-group-name" label="Nombre" label-for="input-1">
                                            <b-form-input id="input-1" v-model="form.name" type="text" required
                                                          placeholder="Escribe el nombre"></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-3">
                                        <b-form-group
                                                id="input-group-3"
                                                label="Imagen"
                                                label-for="input-3"
                                        >
                                            <b-form-file v-if="selectedItem"
                                                    v-model="form.image"
                                                    :state="Boolean(form.image)"
                                                    placeholder="Cargar nuevo archivo ..."
                                                    @change="onImageChange($event)"
                                            ></b-form-file>
                                            <b-form-file v-else
                                                    v-model="form.image"
                                                    :state="Boolean(form.image)"
                                                    placeholder="Cargar nuevo archivo ..."
                                                    required
                                                    @change="onImageChange($event)"
                                            ></b-form-file>
                                            <div class="mt-3">
                                                Fichero seleccionado: {{ form.image ? form.image.name : "" }}
                                            </div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-3" v-if="actualImageResource">
                                        <b-form-group
                                                id="input-group-3"
                                                label="Vista previa"
                                                label-for="input-3"
                                        >
                                            <img :src="actualImageResource" class="image-view" alt=""/>

                                        </b-form-group>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <b-form-group
                                                id="input-group-description"
                                                label="Descripción"
                                                label-for="input-2"
                                        >
                                            <b-textarea
                                                    id="input-2"
                                                    v-model="form.description"
                                                    rows="2"
                                                    required
                                                    placeholder="Escribe la descripción"
                                            ></b-textarea>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-3">
                                        <b-form-group
                                                id="input-group-3"
                                                label="Estado"
                                                label-for="input-3"
                                        >
                                            <b-form-select
                                                    id="input-3"
                                                    v-model="form.status"
                                                    :options="statusList"
                                                    required
                                            ></b-form-select>
                                        </b-form-group>
                                    </div>
                                </div>

                                <b-form-group
                                        id="input-group-value"
                                        label="Reseña del curso"
                                        label-for="input-3"
                                >
                                    <quill-editor
                                            v-model="form.review"
                                            :options="editorOption"
                                            @change="onEditorChange($event)"
                                    />
                                </b-form-group>
                                <div class="row">
                                    <div class="col-md-12 text-right">
                                        <b-button type="submit" variant="primary">Guardar</b-button>
                                        <b-button class="ml-2" type="reset" variant="danger">Resetear
                                        </b-button>
                                    </div>
                                </div>
                            </b-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-overlay>
</template>

<script>
    import "quill/dist/quill.core.css";
    import "quill/dist/quill.snow.css";
    import "quill/dist/quill.bubble.css";
    import {quillEditor} from "vue-quill-editor";
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import {COURSES_STORE_COURSE} from "@/store/modules/courses.module"
    import {COURSES_UPDATE_COURSE} from "@/store/modules/courses.module"
    import {COURSES_GET_COURSES_LIST} from "@/store/modules/courses.module"
    import ApiService from "@/core/services/api.service";

    export default {
        name: "CoursesForm",
        components: {
            quillEditor,
        },
        data() {
            return {
                isLoading: false,
                form: {
                    name: "",
                    image: [],
                    description: "",
                    review: 'Escriba su reseña ...',
                    status: 1
                },
                show: true,
                editorOption: {
                    // Some Quill options...
                },
                userAction: "Nuevo curso",
                statusList: [
                    {text: "Activo", value: 1},
                    {text: "Inactivo", value: 2},
                ],
                actualImageResource: ""
            };
        },
        methods: {
            onSubmitCourse($event) {
                this.isLoading = true
                $event.preventDefault()

                let scope = this

                if(scope.form.image instanceof File) {

                    const formData = new FormData()
                    formData.append('file', scope.form.image);
                    formData.append('azure_container', "icons-systems");

                    ApiService.sendImage(formData).then((response) => {
                        let storeUrl = response.data.url
                        if (typeof storeUrl === 'string' || storeUrl instanceof String) {
                            scope.createOrUpdateCourse(scope, storeUrl)
                        } else {
                            console.error("No se obtuvo una url valida del almacenamiento de la imagen.")
                            scope.isLoading = false
                        }
                    }).catch((error) => {
                        console.error(error)
                    })
                }else{
                    scope.createOrUpdateCourse(scope, scope.actualImageResource)
                }
            },
            createOrUpdateCourse(scope, storeUrl){
                let courseData = {
                    name: scope.form.name,
                    image: storeUrl,
                    description: scope.form.description,
                    review: scope.form.review,
                    status: scope.form.status
                }
                if (scope.selectedItem == null) {
                    scope.$store
                        .dispatch(COURSES_STORE_COURSE, courseData)
                        .then(() => {
                            scope.isLoading = false
                            scope.goList()
                        });
                } else {
                    courseData.id = scope.selectedItem.id
                    scope.$store
                        .dispatch(COURSES_UPDATE_COURSE, courseData)
                        .then(() => {
                            scope.isLoading = false
                            scope.goList()
                        });
                }
            },
            onReset() {
            },
            goList() {
                this.$router.push("courses");
            },
            onEditorChange({
                               quill, html, text
                           }) {
                this.form.review = html
            },
            onImageChange($event) {
                const file = $event.target.files[0];
                this.actualImageResource = URL.createObjectURL(file);
            }
        },
        mounted() {
            if (this.selectedItem == null) {
                this.userAction = "Nuevo curso"
                this.$store.dispatch(SET_BREADCRUMB, [
                    {title: "Cursos", route: "#"},
                    {title: this.userAction},
                ]);
            } else {
                this.userAction = "Modificando curso"
                this.$store.dispatch(SET_BREADCRUMB, [
                    {title: "Cursos", route: "#"},
                    {title: this.userAction},
                ]);

                this.form.name = this.selectedItem.name
                this.form.description = this.selectedItem.description
                this.form.review = this.selectedItem.review
                this.form.status = this.selectedItem.status
                this.actualImageResource = this.selectedItem.image
            }
        },
        computed: {
            selectedItem() {
                return this.$store.state.courses.selectedCourse;
            },
        },
    };
</script>

<style scoped></style>
